@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
    --c-gray-900: #000000;
    --c-gray-800: #1f1f1f;
    --c-gray-700: #2e2e2e;
    --c-gray-600: #313131;
    --c-gray-500: #969593;
    --c-gray-400: #a6a6a6;
    --c-gray-300: #bdbbb7;
    --c-gray-200: #f1f1f1;
    --c-gray-100: #ffffff;

    --c-green-500: #67ffc7;
    --c-olive-500: #e3ffa8;

    --c-white: var(--c-gray-100);

    --c-text-primary: var(--c-gray-100);
    --c-text-secondary: var(--c-gray-200);
    --c-text-tertiary: var(--c-gray-500);
}

.container {
    line-height: 1.5;
    min-height: 100vh;
    font-family: monospace !important;
    background-color: var(--c-gray-900);
    color: var(--c-text-primary);
    display: flex;
    padding-top: 1vw;
    padding-bottom: 2vw;
    justify-content: center;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    img {
        display: block;
        max-width: 100%;
    }

    button,
    select,
    input,
    textarea {
        font: inherit;
    }

    a {
        color: inherit;
    }

    input,
    select,
    a,
    textarea,
    button {
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
        }
    }
}



.responsive-wrapper {
    width: 90%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}

.app {
    min-height: 80vh;
    width: 90vw;
    max-width: 1600px;
    background-color: var(--c-gray-800);
    padding: 2vw 4vw 6vw;
    display: flex;
    flex-direction: column;
}

.app-header {
    display: grid;
    grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr) minmax(max-content,
            400px);
    column-gap: 4rem;
    align-items: flex-end;

    @media (max-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--c-gray-600);
    }
}

.app-header-navigation {
    @media (max-width: 1200px) {
        display: none;
    }
}

.app-header-actions {
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
        display: none;
    }
}

.app-header-actions-buttons {
    display: flex;
    border-left: 1px solid var(--c-gray-600);
    margin-left: 2rem;
    padding-left: 2rem;

    &>*+* {
        margin-left: 1rem;
    }
}

.app-header-mobile {
    display: none;

    @media (max-width: 1200px) {
        display: flex;
    }
}

.app-body {
    height: 100%;
    display: grid;
    grid-template-columns: minmax(min-content, 175px) minmax(max-content, 1fr);

    column-gap: 4rem;
    padding-top: 2.5rem;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;

        &>* {
            margin-bottom: 3.5rem;
        }
    }
}

.app-body-navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1200px) {
        display: none;
    }
}

.app-body-sidebar {}

.footer {
    margin-top: auto;

    h1 {
        font-size: 2rem;
        line-height: 1.125;
        display: flex;
        align-items: flex-start;

        small {
            font-size: 1em;
            margin-left: 0.25em;
        }
    }

    div {
        border-top: 1px solid var(--c-gray-600);
        margin-top: 1.5rem;
        padding-top: 1rem;
        font-size: 0.8rem;
        color: var(--c-text-tertiary);
    }
}

.logo {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid var(--c-gray-600);

    @media (max-width: 1200px) {
        border-bottom: 0;
    }
}

.logo-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.logo-title {
    display: flex;
    flex-direction: column;
    line-height: 1.25;
    margin-left: 0.75rem;

    span:first-child {
        color: white !important;
        font-size: 1rem;
        /* var(--c-text-primary); */
    }

    span:last-child {
        color: var(--c-text-tertiary);
        font-size: 1.5rem;
    }
}

.navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--c-text-tertiary);

    a {
        display: flex;
        align-items: center;
        font-size: 1rem;
        text-decoration: none;
        transition: 0.25s ease;

        * {
            transition: 0.25s ease;
        }

        i {
            margin-right: 0.75rem;
            font-size: 2.5em;
            flex-shrink: 0;
        }

        &+a {
            margin-top: 1.25rem;
        }

        &:hover,
        &:focus {
            transform: translateX(4px);
            color: var(--c-text-primary);
        }
    }
}

.tabs {
    display: flex;
    justify-content: flex-start;
    color: var(--c-text-tertiary);
    border-bottom: 1px solid var(--c-gray-600);

    a {
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-decoration: none;
        border-top: 2px solid transparent;
        display: inline-flex;
        transition: 0.25s ease;
        margin-right: 24px;

        &.active,
        &:hover,
        &:focus {
            color: var(--c-text-primary);
            border-color: var(--c-text-primary);
        }
    }
}

.user-profile {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    cursor: pointer;
    color: var(--c-text-tertiary);
    transition: 0.25s ease;

    &:hover,
    &:focus {
        color: var(--c-text-primary);

        span:last-child {
            box-shadow: 0 0 0 4px var(--c-gray-800), 0 0 0 5px var(--c-text-tertiary);
        }
    }

    span:first-child {
        display: flex;
        font-size: 1.2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--c-gray-600);
        font-weight: 300;
    }

    span:last-child {
        transition: 0.25s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 1.5rem;
        flex-shrink: 0;
    }
}

.icon-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0;
    background-color: transparent;
    border: 1px solid var(--c-gray-500);
    color: var(--c-text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.25s ease;
    flex-shrink: 0;

    &.large {
        width: 42px;
        height: 42px;
        font-size: 2.5em;
    }

    i {
        transition: 0.25s ease;
    }

    &:hover,
    &:focus {
        background-color: var(--c-gray-600);
        box-shadow: 0 0 0 4px var(--c-gray-800), 0 0 0 5px var(--c-text-tertiary);
    }
}

.tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    margin-top: 1.25rem;

    @media (max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.tile {
    padding: 1rem;
    border-radius: 8px;
    background-color: var(--c-olive-500);
    color: var(--c-gray-900);
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: 0.25s ease;

    &:hover {
        transform: translateY(-5px);
    }

    &:focus-within {
        box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-olive-500);
    }

    &:nth-child(2) {
        background-color: var(--c-green-500);

        &:focus-within {
            box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-green-500);
        }
    }

    &:nth-child(3) {
        background-color: rgb(253, 228, 191);
        /* var(--c-gray-300); */

        &:focus-within {
            box-shadow: 0 0 0 2px var(--c-gray-800), 0 0 0 4px var(--c-gray-300);
        }
    }

    .actions {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        cursor: pointer;

        .icon-button {
            color: inherit;
            border-color: inherit;

            &:hover,
            &:focus {
                background-color: transparent;

                i {
                    transform: none;
                }
            }
        }

        /* &:focus {
            box-shadow: none;
        } */

        /* &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        } */
    }

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        cursor: pointer;

        .icon-button {
            color: inherit;
            border-color: inherit;

            &:hover,
            &:focus {
                background-color: transparent;

                i {
                    transform: none;
                }
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.tile-header {
    display: flex;
    align-items: center;

    i {
        font-size: 5em;
    }

    h3 {
        display: flex;
        flex-direction: column;
        line-height: 1.375;
        margin-left: 0.5rem;

        span:first-child {
            font-size: 1.6em;
            font-weight: 600;
        }

        .location {
            font-size: 1.2em;
            font-weight: bold;
        }

        span:last-child {
            font-size: 0.8em;
            font-weight: 200;
            display: flex;
            align-items: center;
        }
    }
}

.service-section {
    &>h2 {
        font-size: 2.5rem;
        margin-bottom: 1.25rem;
    }
}

.service-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>*+* {
        margin-left: 1.25rem;
    }

    @media (max-width: 1000px) {
        display: none;
    }
}

.service-section-footer {
    color: var(--c-text-tertiary);
    margin-top: 1rem;
}

.search-field {
    display: flex;
    flex-grow: 1;
    position: relative;

    input {
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border: 0;
        border-bottom: 1px solid var(--c-gray-600);
        background-color: transparent;
        padding-left: 1.5rem;
    }

    .find-icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dropdown-field {
    display: flex;
    flex-grow: 1;
    position: relative;

    select {
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border: 0;
        border-bottom: 1px solid var(--c-gray-600);
        background-color: transparent;
        padding-right: 1.5rem;
        appearance: none;
        color: var(--c-text-tertiary);
        width: 100%;
    }

    i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.flat-button {
    border-radius: 6px;
    background-color: var(--c-gray-700);
    padding: 0.5em 1.5em;
    border: 0;
    color: var(--c-text-secondary);
    transition: 0.25s ease;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: var(--c-gray-600);
    }
}

.mobile-only {
    display: none;

    @media (max-width: 1000px) {
        display: inline-flex;
    }
}

.action-button-container {
    display: flex;
    gap: 10px; /* Space between buttons */
}

.action-btn {
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 50%; /* Make the button circular */
    font-size: 24px; /* Size of the tick/cross symbol */
    color: #fff; /* Symbol color */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Green circle for the tick button */
.tick-btn {
    background-color: #28a745; /* Green background */
}

/* Red circle for the cross button */
.cross-btn {
    background-color: #dc3545; /* Red background */
}

.action-btn:hover {
    opacity: 0.8; /* Slightly transparent on hover */
}

tbody.text-gray-600{
    td{
        width: 120px;
    }
}

.shorter-length{
    width: 50px !important;
    input{
        height: 20px;
        width: 20px;
    }
}

.question-onboard__prv-btn:disabled {
    color: inherit;
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.selection-check-box:disabled:checked {
    background-color: #cc2a2a; /* Background color for disabled and checked checkbox */
    border-color: #7d1d1d;
    color: red; /* Make sure the checkmark color inherits */
}
