html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: sans-serif;
}

.login {
    &__container {
        height: 100%;
        background-color: beige;
        font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
    }

    &__panel {
        flex: 0.35;
        background-color: beige;
    }

    &__main {
        // flex: 0.65;
        width: 100%;
        background-color: beige;
        // background: linear-gradient(to bottom right, $gradient-start, $gradient-end);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__form {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 10px;
        min-height: 300px;
        padding: 20px;
        width: 80%;
        max-height: 500px;
        scroll-behavior: smooth;
        overflow-y: auto;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;

        button {
            height: 50px;
        }
    }

    &__left {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;

        h2 {
            font-size: 2rem;
            // font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
        }
    }

    &__right {
        flex: 0.5;
        text-align: end;
    }

    &__hints {
        font-size: 0.8rem;
        // width: 60%;
        text-align: center;
        // margin: 0 auto;
        color: green;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 16px 0px;

        label {
            font-size: 1.1rem;
            font-family: monospace;
            font-weight: bold;
        }

        input {
            font-size: 16px;
            font-family: monospace;
            min-height: 50px;
            // width: 350px;
        }

        input::placeholder {
            color: rgb(53 53 53 / 88%) !important;
            /* Change this to your desired color */
            opacity: 1;
            font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
            /* Optional: Ensures the color is fully opaque */
        }
    }

    &__actions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-top: 16px;
    }

    &__signup {
        display: flex;
        align-items: flex-end;

        p {
            // margin-bottom: 16px;
            margin-right: 8px;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
            font-size: 1.1rem;
            color: #1677ff;
        }
    }

    &__eye {
        position: relative;
        bottom: 40px;
        left: 300px;
    }

    &__btn {
        // margin: 16px 0px;
        width: 100px;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
    }
}

.image-panel {
    &__container {
        background: url('../images/passion_image_fit.png') no-repeat center center;
        background-size: contain;
        height: 100%;
    }
}