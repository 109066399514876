.onboard {
    &__container {
        height: 100%;
        // font-family: monospace;
        font-weight: 500;
        font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
    }

    &__panel {
        flex: 0.40;
        background-color: beige;
    }

    &__main {
        width: 100%;
        // flex: 0.60;
        background-color: #F5F5DC;
        // background: linear-gradient(to bottom right, $gradient-start, $gradient-end);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding-left: 40px;
    }

    &__campaign-name {
        background-color: #ff4343;
        padding: 8px;
        border-radius: 8px;
        width: 100px;
        text-align: center;
    }

    &__box {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 10px;
        min-height: 300px;
        padding: 20px;
        width: 80%;
        max-height: 600px;
        scroll-behavior: smooth;
        overflow-y: auto;
        margin: auto;
        display: flex;
    }

    &__left{
        flex: 0.5;

        h2 {
            font-size: 2rem;
            // font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
        }
    }

    &__right{
        flex: 0.5;
    }

    &__box::-webkit-scrollbar {
        width: 12px;
        /* Width of the scrollbar */
    }

    &__box::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* Background of the scrollbar track */
        border-radius: 10px;
    }

    &__box::-webkit-scrollbar-thumb {
        background: #888;
        /* Color of the scrollbar thumb */
        border-radius: 10px;
        border: 2px solid #f1f1f1;
        /* Space around thumb */
    }

    &__box::-webkit-scrollbar-thumb:hover {
        background: #555;
        /* Color of the scrollbar thumb on hover */
    }

    &__box {
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;
    }

    &__box {
        -ms-overflow-style: none;
        /* Hide default scrollbar */
    }

    &__box::-webkit-scrollbar {
        display: none;
        /* Hide default scrollbar */
    }
}

.question {
    &__container {
        min-height: inherit;
        width: 80%;

        h4 {
            color: #666666;
            font-size: 1rem;
            margin-top: 4px;
        }
    }

    &__header {
        h1 {
            margin-top: 0px;
        }
    }

    &__title {
        font-size: 2rem;
        margin-bottom: 4px;
        color: whitesmoke;

        &--required::before {
            content: '*';
            color: red;
        }
    }

    &__field {
        margin: 32px 0px;

        .ant-picker-range {
            background-color: transparent;

            input {
                &::placeholder {
                    color: white;
                }
            }

            span {
                color: white;
            }
        }

        textarea {
            font-size: 16px;
            font-family: monospace;
            color: white;
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }

            &:focus {
                background-color: transparent;
            }

            &::placeholder {
                color: gray;
                font-size: 16px;
            }
        }

        input {
            font-size: 16px;
            font-family: monospace;
            min-height: 50px;

            &::placeholder {
                color: gray;
                font-size: 16px;
            }
        }
    }

    &__footer {
        margin-top: 8px;

        button {
            width: 120px;
            background-color: transparent;
            font-weight: bold;
            font-size: 1rem;
        }
    }

    &__save-btn {
        margin-right: 8px;
        font-weight: 500;
    }

    &__next-btn {

        margin-right: 16px;
        background-color: #2E4053;
        // rgb(231 149 47);
        color: white;
        font-weight: 500;
    }

    &__prv-btn {
        background-color: #2E4053;
        color: white;
        font-weight: 500;

        &:hover {
            background-color: #FFC67D !important;
        }
    }

    &__checkbox-container {
        display: flex;
        align-items: center;

        input {
            height: 20px;
            width: 20px;
            margin-right: 16px;
        }
    }

    &__checkbox-title {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
    }

    &__checkbox-subtitle {
        font-size: 12px;
        margin: 0;
    }

    &__multi-select {
        width: 100%;

        .ant-select-selector {
            padding: 0px 5px;
            background-color: transparent !important;
        }

        .ant-select-selection-item {
            height: 35px;
            justify-content: center;
            align-items: center;
            background-color: antiquewhite !important;
            font-weight: 500;
        }

    }
}

.question-onboard {
    &__container {
        min-height: inherit;
        width: 100%;

        h4 {
            color: #666666;
            font-size: 1rem;
            margin-top: 4px;
        }
    }

    &__header {
        h1 {
            margin-top: 0px;
        }
    }

    &__title {
        font-size: 2rem;
        margin-bottom: 4px;
        color: black;

        &--required::before {
            content: '*';
            color: red;
        }
    }

    &__field {
        margin: 32px 0px;

        .ant-picker-range {
            background-color: transparent;

            input {
                &::placeholder {
                    color: white;
                }
            }

            span {
                color: white;
            }
        }

        textarea {
            font-size: 16px;
            font-family: monospace;
            color: black;
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }

            &:focus {
                background-color: transparent;
            }

            &::placeholder {
                color: gray;
                font-size: 16px;
            }
        }

        input {
            font-size: 16px;
            font-family: monospace;
            min-height: 50px;

            &::placeholder {
                color: gray;
                font-size: 16px;
            }
        }

        input::placeholder {
            color: rgb(53 53 53 / 88%) !important;
            /* Change this to your desired color */
            opacity: 1;
            font-family: 'Google Sans Display', Arial, Helvetica, sans-serif;
            /* Optional: Ensures the color is fully opaque */
        }
    }

    &__footer {
        margin-top: 8px;

        button {
            width: 120px;
            // background-color: transparent;
            font-weight: bold;
            font-size: 1rem;
        }
    }

    &__save-btn {
        margin-right: 8px;
        font-weight: 500;
    }

    &__next-btn {

        margin-right: 16px;
        background-color: #2E4053;
        // rgb(231 149 47);
        color: white;
        font-weight: 500;
    }

    &__prv-btn {
        background-color: #2E4053;
        color: white;
        font-weight: 500;

        &:hover {
            background-color: #FFC67D !important;
        }
    }

    &__checkbox-container {
        display: flex;
        align-items: center;
        // height: 32px;

        input {
            height: 20px;
            width: 20px;
            margin-right: 16px;
        }
    }

    &__checkbox-title {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
    }

    &__checkbox-subtitle {
        font-size: 12px;
        margin: 0;
    }

    &__multi-select {
        width: 100%;

        .ant-select-selector {
            padding: 0px 5px;
            background-color: transparent !important;
        }

        .ant-select-selection-item {
            height: 35px;
            justify-content: center;
            align-items: center;
            background-color: antiquewhite !important;
            font-weight: 500;
        }

    }
}

.social-media {
    &__dropdown {
        height: 45px;
        margin-right: 8px;
    }

    &__input {
        min-height: 45px !important;
    }

    &__btn {
        height: 45px;
    }
}

.PhoneInputCountryIcon {
    width: 50px;
    height: 25px;
    background-color: none;
    box-shadow: none;
}

.PhoneInputCountry {
    width: 50px;
    margin-right: 16px;
}